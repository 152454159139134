<template>
<div class="help-page">
    <div class="video-list">
        <ul class="video-list__list ">
            <li class="video-list__item"
                v-for="link in list"
                :key="link.id"
                :class="{'video-list__list--active' : link.id == videoActive || link.id == videoHover}"
                @mousemove="videoHover = link.id"
                @mouseout="videoHover = null"
                @click.stop="videoActive=link.id;videoAutoplay=true"
            >
                <div class="video-list__header">
                    <SvgIcon class="icon video-list__icon" :name="`help__${link.icon}_icon`"/>
                </div>
                <div class="video-list__body">
                    <h2 >{{ $t('Help.video.'+link.id) }} Video</h2>
                </div>
                <div class="video-list__footer">
                    <!--a class="video-list__link" :href="'/video.html?lang='+lang+'&type='+type+'&video='+link.video" target="_blank">Open in new page</a-->
                    <a class="video-list__link" :href="'/video.html?video='+buildVideoURL(videoUrl+link.video)" target="_blank">{{ $t('Help.link.Open in new page') }}</a>
                </div>
            </li>
        </ul>
    </div>
    <div class="video-container">
        <video v-if="video" class="item-video"  :src="video" controls=true :autoplay="videoAutoplay"></video>
    </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Help",
    data() {
        return {
            videoAutoplay: false,
            videoActive: null,
            videoHover: null,
            videoHost: 'https://privatelabel.xgps.us/video',
            videoUrl: '/help/%lang%_Site_MP4_%type%/',
            langs: {
                'en': "English",
                'es': "Spanish",
                'pt': "English",
            },
            types: {
                'dealer': "Dealer_Only",
                'user': "User",
            },
            videos: {
                dealer: [
                    {id: 'login', icon: 'login', video: 'Login_%lang%.mp4'},
                    {id: 'add unit', icon: 'add_car', video: 'Unit_Add_Car_%lang%.mp4'},
                    {id: 'unit', icon: 'unit', video: 'Unit_Long_Overview_%lang%.mp4'},
                    {id: 'io settings', icon: 'settings', video: 'IO_Settings_Detailed_%lang%.mp4'},
                    {id: 'track', icon: 'track', video: 'Track_History_Detailed_%lang%.mp4'},
                    {id: 'geoitems', icon: 'geoitems', video: 'GeoSites_Detailed_%lang%.mp4'},
                    {id: 'notices', icon: 'notices', video: 'Notices_Detailed_%lang%.mp4'},
                ],
                user: [
                    {id: 'login', icon: 'login', video: 'Login_%lang%.mp4'},
                    {id: 'unit', icon: 'unit', video: 'User_Unit_Settings_%lang%.mp4'},
                    {id: 'track', icon: 'track', video: 'Track_History_Detailed_%lang%.mp4'},
                    {id: 'geoitems', icon: 'geoitems', video: 'GeoSites_Detailed_%lang%.mp4'},
                    {id: 'notices', icon: 'notices', video: 'Notices_Detailed_%lang%.mp4'},
                ],
            }
        }

    },
    computed:{
        ...mapGetters([
            "getAppUser",
            "isDealer",
        ]),
        localLang(){
            return this.$root.$i18n.locale
        },
        lang(){
            return this.langs[this.localLang]
        },
        type(){
            return this.isDealer ? this.types['dealer'] : this.types['user']
        },
        list(){
            return this.isDealer ? this.videos.dealer : this.videos.user
        },
        video(){
            let item = this.list.find(v => v.id == this.videoActive)
            if(!(item>'')) return ''
            return this.videoHost+this.buildVideoURL(this.videoUrl+item.video)
        },
    },
    methods:{
        buildVideoURL(video){
            //video = this.videoUrl+video
            video = video.replaceAll('%lang%', this.lang)
            video = video.replaceAll('%type%', this.type)
            return video
        }
    },
    mounted() {
        this.videoActive = (this.list && this.list.length) ?  this.list[0].id : null
    }
}
</script>

<style scoped>
.help-page{
    padding-left: 5px;
    overflow-y: auto;
}
.video-list{
    padding: 30px 75px;
    background: white;
    margin-bottom: 115px;
}
.video-list__list {
    display: flex;
}
.video-list__list .video-list__item {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: solid 1px var(--color-neutral-100);
    padding: 20px 10px 10px;
    position: relative;
    cursor: pointer;
}
.video-list__list .video-list__item:last-child{
    border-right: solid 1px var(--color-neutral-100);
}
.video-list__list .video-list__list--active {
    background: var(--color-neutral-25);
    border-left: solid 1px var(--color-neutral-25);
}
.video-list__list .video-list__list--active + .video-list__item {
    border-left: solid 1px var(--color-neutral-25);
}
.video-list__list .video-list__list--active:last-child{
    border-right: solid 1px var(--color-neutral-25);
}

.video-list__list .video-list__list--active:after,
.video-list__list .video-list__list--active:before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    background: var(--color-neutral-25);
}
.video-list__list .video-list__list--active:after{
    height: 30px;
    top: -30px;
}
.video-list__list .video-list__list--active:before{
    bottom: -30px;
    height: 30px;
}
.video-list__list .video-list__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-bottom: 14px;
}
.video-list__list h2 {
    font-family: "Inter", sans-serif;
    color: var(--color-font-light);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    cursor: pointer;
    line-height: 19px;
    margin-bottom: 5px;
}
.video-list__list .video-list__icon {
    width: 45px;
    height: 45px;
    color: var(--color-font-light);
}
.video-list__list .video-list__link:visited {
    color: var(--color-font-light);
}
.video-list__list .video-list__link {
    font-family: "Open Sans", sans-serif;
    color: var(--color-font-light);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px;
    font-size: 12px;
    line-height: 15px;
    transition: .2s;
    text-decoration: underline;
}
.video-list__list .video-list__list--active .video-list__icon,
.video-list__list .video-list__list--active h2,
.video-list__list .video-list__list--active .video-list__link{
    color: var(--color-primary-500);
}
.video-list__list .video-list__link:hover {
    color: var(--color-font-mute);
    transition: .2s;
}
.video-list__list  .video-list__body {
    margin-bottom: 10px;
}
.video-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
}
.item-video {
    width: 100%;
    max-width: 968px;
    filter: drop-shadow(0px 10px 16px rgba(23, 32, 63, 0.5))
}


@media (max-width: 1395px){
    .video-list__list .video-list__icon {
        width: 25px;
        height: 25px;
    }
    .is_new-design h2 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .video-list__list .video-list__header{
        margin-bottom: 0;
    }
    .video-list__list .video-list__list .video-list__body{
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .video-list__list .video-list__footer{
        align-items: center;
    }
}
@media (max-width: 1268px) {
    .video-list {
        margin-bottom: 35px;
    }
    .video-list__list  .video-list__body {
        margin-bottom: 0;
    }
    .video-list__list .video-list__item{
        border: none;
        border-top: solid 1px var(--color-neutral-100);
    }
    .video-list__list .video-list__item:last-child{
        border-right: none;
        border-bottom: solid 1px var(--color-neutral-100);
    }
    .video-list__list .video-list__list--active{
        border-top: solid 1px var(--color-neutral-25);
    }
    .video-list__list .video-list__list--active + .video-list__item {
        border-left: none;
        border-top: solid 1px var(--color-neutral-25);
    }
    .video-list__list .video-list__item > div{
        margin-right: 10px;
    }
    .video-list__list {
        display: block;
    }
    .video-list__list .video-list__item,
    .video-list__list .video-list__list--active{
        position: relative;
        width: 100%;
        flex-direction: row;
        padding: 0;
        z-index: 10;
    }
    .video-list__list .video-list__list--active:before{
     content: none;
    }
    .video-list__list .video-list__list--active:after {
        z-index: -1;
        height: calc(100% + 2px);
        width: calc(100% + 50px);
        top: -1px;
        bottom: 0;
    }
    .video-list__list .video-list__list--active:after{
        left: -25px;
    }
    .video-list__list .video-list__link {
        width: 100%;
    }
}
@media (max-width: 1086px){

}
@media (max-height: 811px){
    .item-video{
        max-width: 800px;
    }
    .video-list {
        margin-bottom: 50px;
    }
}
@media (max-height: 760px){
    .item-video{
        max-width: 600px;
    }
}
@media (max-width: 926px) {
    .video-list {
        padding: 30px 25px;
    }
}

</style>